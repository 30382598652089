import { Component } from '@angular/core';
import {AuthService} from './shared/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tree of light Admin';

  constructor(private toaster: ToastrService, private spinner: NgxSpinnerService, private user: AuthService, private router: Router) {

  }

  doLogout() {

    this.spinner.show();

    this.user.logout().then(() => {

      this.spinner.hide();

      this.toaster.success('Logout Successful', 'Success');

      this.router.navigateByUrl('/login');

    });

  }
}
