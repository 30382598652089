import { Component, OnInit } from "@angular/core";
import { OrderModel } from "../../models/order.model";
import { BackendService } from "../../shared/backend.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ExportSalesOrdersService } from "../../shared/export-sales-orders.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"]
})
export class OrdersComponent implements OnInit {
  globeDetailsForm: FormGroup;

  orders: any[];

  p = 1;

  order: any = {};

  orderDetail: any = {};

  globeDetail: any = {};

  ordersItems: any[];

  globeList: any[];

  available = false;

  globePrice = 0;

  globeTitle = "";

  cartTotal = 0;

  reference = "";

  editMessage = false;

  searchText: string;

  constructor(
    private bs: BackendService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private es: ExportSalesOrdersService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.ordersItems = [];

    this.showAllOrders();

    this.buildGlobeDetailsForm();
  }

  private recordPayment(orderNumber): void {
    // get cart items or order items
    console.log(orderNumber);

    let cartItems = [];

    this.bs.recordPayment(orderNumber).subscribe(data => {
      cartItems = data;

      // loop through the cartItems/OrderItems and add entry on tree

      let i;
      for (i = 0; i < cartItems.length; i++) {
        console.log(cartItems[i]);
        this.bs.addBulbToTree(cartItems[i]);
      }

      // update status of order
      this.bs
        .updateOrderStatus(orderNumber)
        .then(() => {
          this.showAllOrders();
        })
        .catch(error => {
          this.toastr.error("Error", error);
        });
    });
  }

  private buildGlobeDetailsForm(): void {
    this.globeDetailsForm = this.fb.group({
      title: ["", Validators.required],
      message: ["", Validators.required],
      forWho: ["", Validators.required],
      tol: ["", Validators.required]
    });

    this.reference = Date.now() + "";
  }

  private showAllOrders(): void {
    this.spinner.show();

    this.bs.listAllOrders().subscribe(ordersList => {
      this.orders = [];
      // console.log(this.orders);
      ordersList.forEach(a => {
        const globe: any = a.payload.doc.data();

        globe.id = a.payload.doc.id;

        this.orders.push(globe);
      });

      if (this.orders.length > 0) {
        this.available = true;
      } else {
        this.available = false;
      }

      this.spinner.hide();
    });
  }

  private orderDetails(order: OrderModel): void {
    this.orderDetail = order;

    this.listOrderBulbsForCustomer();
  }

  // set globe color
  private setGlobeColor(event: any) {
    this.globeDetail.color = event.color.hex;
  }

  private selectedGlobePrice(event: any): void {
    const str = event.target.value;
    const res = str.split("-");
    this.globeTitle = res[0];
    this.globePrice = parseInt(res[1], 10);
  }

  private addItemToList(item: any): void {
    item.title = this.globeTitle;

    item.price = this.globePrice;

    // set globe tree location
    item.left = Math.floor(Math.random() * (650 - 95) + 95) + "px";

    item.top = Math.floor(Math.random() * (350 - 10) + 10) + "px";

    item.color = this.globeDetail.color;
    // set tree url
    // const bulbColor = event.color.hex;

    item.reference = this.reference;

    item.treeUrl =
      "../../../assets/" + this.globeDetail.color.substring(1) + ".png";

    this.bs.addOrderItemToList(item).then(() => {
      this.listOrderItems();
    });

    this.buildGlobeDetailsForm();
  }

  private processManualOrder(order: any) {
    if (this.order != null) {
      if (order.customer === undefined) {
        this.toastr.error("Customer name is required", "Required Field Empty");

        return;
      }

      if (order.email === undefined) {
        this.toastr.error(
          "Customer email address is required",
          "Required Field Empty"
        );

        return;
      }

      if (order.number === undefined) {
        this.toastr.error(
          "Customer contact number is required",
          "Required Field Empty"
        );

        return;
      }

      if (order.address === undefined) {
        this.toastr.error(
          "Customer billing address is required",
          "Required Field Empty"
        );

        return;
      }

      const today = new Date();
      const dd = String(today.getDate());
      const mm = String(today.getMonth() + 1);
      const yyyy = today.getFullYear();

      order.orderDate = dd + "." + mm + "." + yyyy;

      order.reference = this.reference;

      order.status = "Completed";

      order.type = "Office";

      order.total = this.cartTotal;
      // get details from the form

      this.bs
        .processManualOrder(order)
        .then(() => {
          this.spinner.show();

          this.toastr.success("Order created successfully", "TOL");

          this.addGlobeToTree(order.reference);

          this.order = {};
        })
        .catch(err => {
          this.spinner.hide();

          this.toastr.error(err.message, "Error Occurred");
        });
    } else {
      this.toastr.warning(
        "An unexpected error has occurred. Please try again",
        "Server Error"
      );
    }
  }

  private listOrderBulbsForCustomer() {
    this.bs.listCustomerOrderBulbs(this.orderDetail.id).subscribe(globes => {
      this.globeList = [];

      globes.forEach(a => {
        const bulb: any = a.payload.doc.data();

        bulb.id = a.payload.doc.id;

        this.globeList.push(bulb);
      });
    });
  }

  private listOrderItems() {
    this.bs.getOrderItemToList().subscribe(items => {
      this.ordersItems = [];

      this.cartTotal = 0;

      items.forEach(a => {
        const item: any = a.payload.doc.data();

        item.id = a.payload.doc.id;

        this.ordersItems.push(item);

        this.cartTotal = this.cartTotal + item.price;
      });
    });
  }

  private deleteOrder(orderId: string): void {
    this.toastr.success(
      "Order #" + orderId + " deleted successfully",
      "Success"
    );

    this.bs
      .deleteOrder(orderId)
      .then(() => {
        this.toastr.success(
          "Order #" + orderId + " deleted successfully",
          "Success"
        );
      })
      .catch(err => {
        this.toastr.error(err, "Error Occurred");
      });
  }

  private addGlobeToTree(id: string): void {
    let i;

    for (i = 0; i < this.ordersItems.length; i++) {
      this.ordersItems[i].reference = id;
      // get globes on the array
      this.bs
        .addBulbToTree(this.ordersItems[i])
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    }

    for (i = 0; i < this.ordersItems.length; i++) {
      this.bs.deleteCurrentOrderItems(this.ordersItems[i]);
    }

    this.spinner.hide();
  }

  private updateMessage(id: string, message: string) {
    this.bs
      .updateGlobeMessage(id, message)
      .then(() => {
        this.editMessage = false;

        this.toastr.success("Updated successfully", "TOL");
      })
      .catch(err => {
        this.toastr.error(err);

        console.log(err);
      });
  }

  private exportAsXLSX(): void {
    this.es.exportAsExcelFile(this.orders, "orders");
  }
}
