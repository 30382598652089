import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrdersComponent } from './components/orders/orders.component';
import { BulbsComponent } from './components/bulbs/bulbs.component';
import {BackendService} from './shared/backend.service';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ToastrModule } from 'ngx-toastr';
import { BuyersComponent } from './components/buyers/buyers.component';
import { UsersComponent } from './components/users/users.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { LoginComponent } from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ColorCircleModule } from 'ngx-color/circle';
import {ExportSalesOrdersService} from './shared/export-sales-orders.service';
import { TreeComponent } from './components/tree/tree.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxConfirmBoxModule, NgxConfirmBoxService } from 'ngx-confirm-box';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    OrdersComponent,
    BulbsComponent,
    AnalyticsComponent,
    BuyersComponent,
    UsersComponent,
    LoginComponent,
    TreeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxConfirmBoxModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    ColorCircleModule,
    FormsModule
  ],
  providers: [BackendService, ExportSalesOrdersService, NgxConfirmBoxService],
  bootstrap: [AppComponent]
})
export class AppModule { }
