import { Component, OnInit } from '@angular/core';
import {BuyerModel} from '../../models/buyer.model';
import {BackendService} from '../../shared/backend.service';
import {OrderModel} from '../../models/order.model';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExportSalesOrdersService} from '../../shared/export-sales-orders.service';
import {NgxConfirmBoxService} from 'ngx-confirm-box';

@Component({
  selector: 'app-buyers',
  templateUrl: './buyers.component.html',
  styleUrls: ['./buyers.component.css']
})
export class BuyersComponent implements OnInit {

  buyers: any[];

  available = false;

  searchText ;

  bgColor           = 'rgba(0,0,0,0.5)'; // overlay background color
  confirmHeading    = '';
  confirmContent    = 'Are you sure want to delete all customers?';
  confirmCanceltext = 'CANCEL';
  confirmOkaytext   = 'YES';

  p = 1;

  constructor(private bs: BackendService,
              private ts: ToastrService,
              private confirmBox: NgxConfirmBoxService,
              private spinner: NgxSpinnerService,
              private es: ExportSalesOrdersService) { }

  ngOnInit() {

    this.spinner.show();

    this.getGlobeBuyers();

  }

  private deleteAllCustomers(): void {

    this.confirmBox.show();

  }

  private confirmChange(showConfirm: boolean) {
    if (showConfirm) {
      let i;

      for (i = 0; i <= this.buyers.length; i++) {

        this.bs.deleteCustomer(this.buyers[i].id);

      }

      this.ts.success('Globes deleted');
    }
  }

  private deleteCustomer(orderId: string): void {

    this.bs.deleteCustomer(orderId).then(() => {

      this.ts.success('Customer deleted successfully', 'Success');
    })
      .catch((err) => {
        this.ts.error(err, 'Error Occurred');
      });
  }

  private getGlobeBuyers(): void {

    this.spinner.show();

    this.bs.listGlobeBuyers().subscribe( customers => {

      this.buyers = [];

      customers.forEach(a => {

        const customer: any = a.payload.doc.data();

        customer.id = a.payload.doc.id;

        this.buyers.push(customer);

      });

      if (this.buyers.length > 0) {

        this.available = true;

      } else {

        this.available = false;

      }

      this.spinner.hide();

    });

  }

  private exportAsXLSX(): void {
    this.es.exportAsExcelFile(this.buyers, 'Customers');
  }

}
