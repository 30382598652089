import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {BulbsComponent} from './components/bulbs/bulbs.component';
import {OrdersComponent} from './components/orders/orders.component';
import {BuyersComponent} from './components/buyers/buyers.component';
import {UsersComponent} from './components/users/users.component';
import {AnalyticsComponent} from './components/analytics/analytics.component';
import {LoginComponent} from './components/login/login.component';
import {TreeComponent} from './components/tree/tree.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'bulbs', component: BulbsComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'buyers', component: BuyersComponent },
  { path: 'users', component: UsersComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'tree', component: TreeComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
