import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../shared/backend.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ExportSalesOrdersService} from '../../shared/export-sales-orders.service';
import { NgxConfirmBoxService } from 'ngx-confirm-box';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  treeGlobes: any = [];

  p = 1;

  available = false;

  bgColor           = 'rgba(0,0,0,0.5)'; // overlay background color
  confirmHeading    = '';
  confirmContent    = 'Are you sure want to delete all globes?';
  confirmCanceltext = 'CANCEL';
  confirmOkaytext   = 'YES';

  constructor(private bs: BackendService,
              private confirmBox: NgxConfirmBoxService,
              private es: ExportSalesOrdersService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService) { }

  ngOnInit() {

    this.listAllTreeGlobes();

  }

  private listAllTreeGlobes(): void {

    this.spinner.show();

    this.bs.listTreeGlobes().subscribe( globes => {

      this.treeGlobes = [];

      globes.forEach(a => {

        const globe: any = a.payload.doc.data();

        globe.id = a.payload.doc.id;

        this.treeGlobes.push(globe);

      });

      if (this.treeGlobes.length > 0) {

        this.available = true;

      } else {

        this.available = false;

      }

      this.spinner.hide();

    });
  }

  private deleteAllTreeGlobes(): void {

    this.confirmBox.show();
  }

  private confirmChange(showConfirm: boolean) {
    if (showConfirm) {
      let i;

      for (i = 0; i <= this.treeGlobes.length; i++) {

        console.log(this.treeGlobes[i].id);
        this.bs.deleteTreeGlobe(this.treeGlobes[i].id);

      }

      this.toaster.success('Globes deleted');
    }
  }

  private deleteGlobe(id: string): void {

    this.bs.deleteTreeGlobe(id).then(() => {
      this.toaster.success('Globe removed from tree', 'TOL');
    }).catch(err => {
      this.toaster.error(err, 'Server Error Occurred');
    });
  }

  private exportAsXLSX(): void {
    this.es.exportAsExcelFile(this.treeGlobes, 'Tree Globes');
  }

}
