import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../shared/auth.service";
import { Router } from "@angular/router";
import { BackendService } from "../../shared/backend.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  username: any;

  password: any;

  constructor(
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private bs: BackendService,
    private user: AuthService,
    private router: Router
  ) {}

  ngOnInit() {}

  doLogin(username, password) {
    this.spinner.show();

    if (username === undefined || password === undefined) {
      this.toaster.error(
        "Username/ Password fields cannot be empty",
        "Login Error"
      );

      return;
    }

    this.user
      .login(username, password)
      .then(result => {
        this.bs.userProfile(result.user.uid).subscribe(res => {
          if (res == null) {
            this.user.logout();

            this.toaster.error(
              "Your account is not authorised",
              "Access Declined"
            );

            this.spinner.hide();

            this.router.navigateByUrl("/login");
          } else {
            this.toaster.success("Login Successful", "Success");

            this.router.navigateByUrl("/dashboard");
          }
        });
      })
      .catch(err => {
        this.toaster.error(err.message, "Authentication Failed");
      });
  }
}
