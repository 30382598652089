import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class BackendService {
  constructor(private afs: AngularFirestore) {}

  listAllOrders() {
    return this.afs.collection("orders").snapshotChanges();
  }

  listTreeGlobes() {
    return this.afs.collection("treeGlobes").snapshotChanges();
  }

  deleteTreeGlobe(id: string) {
    return this.afs
      .collection("treeGlobes")
      .doc(id)
      .delete();
  }

  deleteCustomer(id: string) {
    return this.afs
      .collection("customers")
      .doc(id)
      .delete();
  }

  addOrderItemToList(orderItem) {
    return this.afs.collection("officeOrder").add(orderItem);
  }

  getOrderItemToList() {
    return this.afs.collection("officeOrder").snapshotChanges();
  }

  processManualOrder(order) {
    return this.afs
      .collection("orders")
      .doc(order.reference)
      .set(order);
  }

  updateOrderStatus(number) {
    return this.afs
      .collection("orders")
      .doc(number)
      .update({ status: "Completed" });
  }

  addBulbToTree(bulb: any) {
    return this.afs.collection("treeGlobes").add(bulb);
  }

  deleteCurrentOrderItems(item: any) {
    this.afs
      .collection("officeOrder")
      .doc(item.id)
      .delete();
  }
  listUsers() {
    return this.afs.collection("users").valueChanges();
  }

  updateGlobe(globe: any) {
    return this.afs
      .collection("bulbs")
      .doc(globe.id)
      .update(globe);
  }

  listGlobeBuyers() {
    return this.afs.collection("customers").snapshotChanges();
  }

  listCustomerOrderBulbs(reference: string) {
    console.log(reference + " from service");

    return this.afs
      .collection("treeGlobes", ref => ref.where("reference", "==", reference))
      .snapshotChanges();
  }

  updateGlobeMessage(id: string, newMessage: string) {
    return this.afs
      .collection("treeGlobes")
      .doc(id)
      .update({ message: newMessage });
  }

  userProfile(uid: string) {
    return this.afs
      .collection("users")
      .doc(uid)
      .valueChanges();
  }

  listAllBulbs() {
    return this.afs.collection("bulbs").snapshotChanges();
  }

  deleteOrder(id: string) {
    return this.afs
      .collection("orders")
      .doc(id)
      .delete();
  }

  createManualOrder(order: any) {
    return this.afs
      .collection("orders")
      .doc(order.reference)
      .set(order);
  }

  deleteBuyer(id: string) {
    return this.afs
      .collection("buyers")
      .doc(id)
      .delete();
  }

  recordPayment(orderNumber) {
    return this.afs
      .collection("cartItems", ref =>
        ref.where("reference", "==", parseInt(orderNumber, 10))
      )
      .valueChanges();
  }
}
