// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyApSNTMPKwM0f97iAWV1k9YUo9N8hG6Xpw",
//     authDomain: "treeoflighttest.firebaseapp.com",
//     databaseURL: "https://treeoflighttest.firebaseio.com",
//     projectId: "treeoflighttest",
//     storageBucket: "treeoflighttest.appspot.com",
//     messagingSenderId: "706011365958",
//     appId: "1:706011365958:web:6ccbfcc69ab16ce24ec047"
//   }
// };

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD-06C_l7m5z3q3UvipB2ORiTabJ7GBNh8",
    authDomain: "tree-of-light-webapp.firebaseapp.com",
    databaseURL: "https://tree-of-light-webapp.firebaseio.com",
    projectId: "tree-of-light-webapp",
    storageBucket: "tree-of-light-webapp.appspot.com",
    messagingSenderId: "128529281810",
    appId: "1:128529281810:web:dbf93e807a4cd83e5c79cb"
  }
};
