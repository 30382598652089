import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../shared/backend.service';
import {BuyerModel} from '../../models/buyer.model';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  bulbsSales = [];

  bulbs = [];

  buyers = [];

  totalBulbSales = 0;

  totalBuyers = 0;

  numberOfGlobesDonated = 0;

  numberOfAvailableGlobes = 0;

  constructor(private bs: BackendService, private fs: AngularFirestore) { }

  ngOnInit() {

    this.getTotalNumberOfGlobes();

    this.calculateTotalGlobeSales();

    this.getTotalBuyers();

    this.getTotalBulbs();

  }

  getTotalBuyers() {

    this.fs.collection('customers').valueChanges().subscribe( value => {

      this.buyers = value;

      this.totalBuyers = value.length;

    });

  }

  getTotalNumberOfGlobes() {

    this.fs.collection('bulbs').valueChanges().subscribe( values => {

      this.numberOfAvailableGlobes = values.length;

    });
  }

  getTotalBulbs() {

    this.fs.collection('treeGlobes').valueChanges().subscribe( value => {

      this.numberOfGlobesDonated = value.length;

    });

  }

  calculateTotalGlobeSales() {

    this.fs.collection('treeGlobes').valueChanges().subscribe( value => {

      let i;

      this.bulbsSales = value;

      for (i = 0; i < value.length; i++) {

        this.totalBulbSales = this.totalBulbSales + this.bulbsSales[i].price;

      }

    });

  }

}
