import { Injectable } from '@angular/core';
import {of as observableOf} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';
import {auth} from 'firebase';

@Injectable({
  providedIn: 'root'
})
  export class AuthService {
  uid: any;
  isAdmin: any;
  displayName = '';

  constructor(private afAuth: AngularFireAuth) {

    this.uid = this.afAuth.authState.pipe(
      map(authState => {
        if (!authState) {
          return null;
        } else {
          return authState.uid;
        }
      }),
    );

    this.isAdmin = observableOf(true);

  }

  login(username: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(username, password);
  }

  logout() {
      return this.afAuth.auth.signOut();
    }
}
