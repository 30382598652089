import { Component, OnInit } from '@angular/core';
import {BulbModel} from '../../models/bulb.model';
import {BackendService} from '../../shared/backend.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-bulbs',
  templateUrl: './bulbs.component.html',
  styleUrls: ['./bulbs.component.css']
})
export class BulbsComponent implements OnInit {

  bulbs: any[];

  editPrice = '';

  editId = '';

  editTitle = '';

  editDescription = '';

  available = false;

  constructor(private bs: BackendService, private spinner: NgxSpinnerService, private toaster: ToastrService ) { }

  ngOnInit() {

    this.spinner.show();

    this.bs.listAllBulbs().subscribe( globeList => {

      this.bulbs = [];

      globeList.forEach(a => {

        const globe: any = a.payload.doc.data();

        globe.id = a.payload.doc.id;

        this.bulbs.push(globe);

      });

      if (this.bulbs.length > 0) {

        this.available = true;

      } else {

        this.available = false;

      }

      this.spinner.hide();

    });

  }


  private editGlobe(bulb: any): void {

    this.editTitle = bulb.title;

    this.editDescription = bulb.description;

    this.editPrice = bulb.cost;

    this.editId = bulb.id;
  }

  private updateGlobe(id: any): void {

    this.spinner.show();

    const globe: any = {};

    globe.id = id;

    globe.title = this.editTitle;

    globe.description = this.editDescription;

    globe.cost = this.editPrice;

    this.bs.updateGlobe(globe).then( () => {

       this.spinner.hide();

       this.toaster.success('Globe updated successfully', 'TOL');

    });
  }

}
