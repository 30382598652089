import { Component, OnInit } from '@angular/core';
import {OrderModel} from '../../models/order.model';
import {UserModel} from '../../models/user.model';
import {BackendService} from '../../shared/backend.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: any[];

  available = false;

  constructor(private bs: BackendService, private ts: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.listUsers();

  }

  suspendUser(id: string) {


  }

  listUsers() {
    this.bs.listUsers().subscribe(data => {

      this.users = data;

      if  (this.users.length > 0) {

        this.available = true;

      } else {

        this.available = false;

      }

      this.spinner.hide();

    });

  }

}
